<form (submit)="importCsvData()" class="flex flex-col mx-[2vw] mt-[1vw]">
    <div class="text-primary font-light w-full mb-5 flex flex-col justify-between px-4">
        <div class="text-center text-xl md:text-3xl mb-1">CSV Import</div>
        <div class="flex flex-col gap-4">
            <mat-form-field appearance="outline" class="mat-form-field-normal !mb-0 !text-sm">
                <mat-label>Import Type</mat-label>
                <mat-select [ngModelOptions]="{standalone: true}" name="csvType" [(ngModel)]="csvType" class="!flex">
                    <mat-option *ngFor="let option of options" [value]="option.value">{{ option.text }}</mat-option>
                </mat-select>
            </mat-form-field>
            <financehub-drag-drop-template accept=".csv" (fileSelected)="fileSelected($event)"/>
            <button mat-stroked-button color="primary" type="button" (click)="resetCollection()">
                {{config.button.resetCollection}}
              </button>
            <button mat-raised-button color="primary" type="submit">
                {{config.button.import}}
            </button>
        </div>
    </div>
</form>