import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from '../snackbar-template/snackbar-template.component';
import { ProjectSettings } from 'src/assets/config/project-config';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { CompanyService } from 'src/app/services/crm/company.service';
import { AddressesService } from 'src/app/services/crm/addresses.service';
import { PersonsService } from 'src/app/services/crm/persons.service';

@Component({
  selector: 'financehub-input-template',
  templateUrl: './input-template.component.html',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    NgIf,
    MatSelectModule,
    NgFor,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    NgClass,
    MatSnackBarModule,
    MatTooltipModule
  ],
})
export class InputTemplateComponent implements OnInit{
  @Input() label!: string;
  @Input() entity!: string;
  @Input() readonly!: boolean;
  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() type = "text";
  @Input() optionsArray!: any;
  @Input() displayWith!: any;
  @Input() displayField!: string;
  @Input() displayFieldSecondary: string | null = null;
  @Input() optionValue!: string;
  @Input() colSpan!: string;
  @Input() placeholder!: string;
  @Output() autocompleteChanged = new EventEmitter();
  @Output() autocompleteSelected = new EventEmitter();
  @Output() selectionChanged = new EventEmitter();


  constructor(
    private _snackBar: MatSnackBar, 
    public financialService: FinancialService, 
    public companyService: CompanyService,
    public addressesService: AddressesService,
    public personsService: PersonsService
  ) {}
  config = ProjectSettings;
  startDate!: Date;
  showTooltip = false;

  ngOnInit(): void {
    this.startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 35));
  }

  checkTooltip(name: any): void {
    this.showTooltip = this.formGroup.controls[name].value ? true : false;
  }

  onInputCheckTooltip(name: any, entity: any): void {
    this.showTooltip = this.formGroup.controls[name].value ? true : false;
    if (entity === 'company' && this.companyService.companySelected) {
      this.companyService.editCompany = true;
    }
    if (entity === 'address' && this.addressesService.addressSelected) {
      this.addressesService.editAddress = true;
    }
    if (entity === 'person' && this.personsService.personSelected) {
      this.personsService.editPerson = true;
    }
  }

  onAutocompleteChange(event: any) {
    this.autocompleteChanged.emit(event);
  }

  onAutocompleteSelected(event: any) {
    this.autocompleteSelected.emit(event);
  }

  onSelectionChange(event: any) {
    this.selectionChanged.emit(event);
  }

  navigateToLink(type: string,  field: any) {
    if (type === 'link' && field.disabled && field.value) {
      let absoluteURL = field.value;
      if (!absoluteURL.startsWith('http://') && !absoluteURL.startsWith('https://')) {
        if (absoluteURL.startsWith('www.')) {
          absoluteURL = `http://${absoluteURL}`;
        } else {
          absoluteURL = `http://www.${absoluteURL}`;
        }
      }
      window.open(absoluteURL, '_blank');
    }
  }

  copyText(control: any) {
    const text = control.value;
    navigator.clipboard.writeText(text);
    this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: 'copied!' } });
  }
}
