<financehub-list-template 
  entity="company"
  heading="Companies"
  [addButton]="config.button.addCompany"
  [array]="config.tableColumns.companies"
  [dataSource]="dataSource"
  [sortActive]="sortState.active"
  [sortDirection]="sortState.direction"
  [loading]="loading"
  (clickedDelete)="openDeleteDialog($event, 'companies')"
  (filterChanged)="onFilterChange($event)"
  (filterDelete)="deleteFilter()"
  (sortChanged)="refetchEntities('companies')"
  (pageChanged)="refetchEntities('companies')"
/>