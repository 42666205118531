import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { NgIf, NgClass, NgFor, DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { CompanyService } from '../../../services/crm/company.service';
import { InvoiceService } from '../../../services/finance/invoice.service';
import { AddressesService } from '../../../services/crm/addresses.service';
import { PersonsService } from '../../../services/crm/persons.service';
import { Address, AddressType } from '../../../interfaces/address';
import { Person } from '../../../interfaces/person';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { OrbDemoComponent } from '../../../orb-demo/orb-demo.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatInputModule } from '@angular/material/input';
import { DialogTemplateComponent } from '../../../components/dialog-template/dialog-template.component';
import { Dialog } from '@angular/cdk/dialog';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { DetailTemplateComponent } from 'src/app/components/detail-template/detail-template.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { defaultConfig, FilterConfig } from 'src/app/interfaces/filter-config';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'financehub-company-detail',
  templateUrl: './company-detail.component.html',
  standalone: true,
  imports: [
    RouterLink,
    NgIf,
    MatToolbarModule,
    NgClass,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    NgFor,
    MatTabsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    OrbDemoComponent,
    DetailTemplateComponent,
    MatButtonModule
  ],
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    private companyService: CompanyService,
    private addressesService: AddressesService,
    private personsService: PersonsService,
    public route: ActivatedRoute,
    private datePipe: DatePipe,
    public dialog: Dialog,
    private _snackBar: MatSnackBar,
    public financialService: FinancialService
  ) {}

  uuid!: string;
  config!: any;
  filterConfig: FilterConfig = defaultConfig;
  
  //company
  private querySubscription!: Subscription;
  companyForm!: FormGroup;
  company!: any; 
  editCompanyActive = false;

  //address
  dataSourceAddresses!: MatTableDataSource<Address>;
  editAddressesActive = false;
  availableAddresses: Address[] = [];
  addressToDelete!: any;
  valueFilterAdresses = '';
  loadingAddresses!: boolean;

  //persons
  dataSourcePersons!: MatTableDataSource<Person>;
  editPersonsActive = false;
  availablePersons: Person[] = [];
  personsOfCompany: Person[] = [];
  addPersonOverlay = false;
  selectedPerson = '';
  deletePersonOverlay = false;
  personToDelete!: any;
  valueFilterPersons = '';
  companyPersons: Person[] = [];
  loadingPersons!: boolean;

  @ViewChild('addresses', { read: MatSort, static: true }) sortAddresses!: MatSort;
  @ViewChild('persons', { read: MatSort, static: true }) sortPersons!: MatSort;
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    if (!isInputFocused) {
      switch (event.key) {
        case '1':
          this.matTabGroup.selectedIndex = 0;
          break;
        case '2':
          this.matTabGroup.selectedIndex = 1;
          break;
        case '3':
          this.matTabGroup.selectedIndex = 2;
          break;
        default:
          break;
      }
    }
  }

  ngOnInit() {
    this.config = ProjectSettings;
    this.createOrUpdateCompanyForm();
    this.getDetails();
  }

  getDetails() {
    this.route.paramMap.subscribe((params) => {
      this.uuid = this.financialService.entityEditUuid;
      this.querySubscription = this.companyService.getCompany(this.uuid).subscribe(({ data, loading }) => {
        this.getDetailsQuerySubscription(data);
      });
    });
  }

  getDetailsQuerySubscription(data?: any) {
    if (data) {
      this.company = { ...data.company };
    }
    this.createOrUpdateCompanyForm(this.company);
    this.addressesService.addressesOfCompany = [
      ...this.company.delivery_addresses.map((dA: Address) => ({
        delivery: true,
        ...dA
      })),
      ...this.company.invoice_addresses.map((iA: Address) => ({
        invoice: true,
        ...iA
      }))
    ].reduce((acc, address) => {
      const existingAddress = acc.find((a: any) => a.uuid === address.uuid);
      if (existingAddress) {
        Object.assign(existingAddress, address); // Merge the properties
      } else {
        acc.push(address);
      }
      return acc;
    }, []);
    this.personsOfCompany = [...this.company.persons];
    this.getAddresses();
    this.getPersons();
  }

  createOrUpdateCompanyForm(company: any = {}) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    this.config.fields.company.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      const value = company[field.controlName] ?? null;
      formGroupConfig[field.controlName] = new FormControl(
        { value: value, disabled: true },
        validators
      );
    });
    this.companyForm = new FormGroup(formGroupConfig);
  }

  getAddresses() {
    this.loadingAddresses = true;
    this.addressesService.getAddresses(this.filterConfig).subscribe(({ data, loading }) => {
      this.addressesService.availableAddresses = data.addresses.data.filter((addr: Address) => {
        return !this.addressIsInUse(addr.uuid, 'invoice') || !this.addressIsInUse(addr.uuid, 'delivery');
      });
      this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesService.addressesOfCompany);
      this.dataSourceAddresses.sort = this.sortAddresses;
      this.loadingAddresses = false;
    });
  }
  
  getPersons() {
    this.loadingPersons = true;
    this.personsService.getPersons(this.filterConfig).subscribe(({ data, loading }) => {
      this.personsService.availablePersons = data.persons.data.filter((person: Person) => {
        return (
          this.company.persons.findIndex((p: Person) => {
            return p.uuid === person.uuid;
          }) === -1
        );
      });
      this.dataSourcePersons = new MatTableDataSource<Person>(this.personsOfCompany);
      this.dataSourcePersons.sort = this.sortPersons;
      const updatedData = this.dataSourcePersons.data.map((item) => ({
        ...item,
        birthday: item.birthday ? this.datePipe.transform(new Date(item.birthday)) : null,
        ...this.company.company_persons.find((p: any) => p.person_uuid === item.uuid)
      }));
      this.dataSourcePersons.data = updatedData;
      this.loadingPersons = false;
    });
  }

  //mat labels
  getLabel(section: string) {
    if (section === 'address') {
      const count = this.dataSourceAddresses?.data?.length;
      return `2 - ${this.config.labels.addresses} (${count})`;
    } else {
      const count = this.dataSourcePersons?.data?.length;
      return `3 - ${this.config.labels.persons} (${count})`;
    }
  }

  //button edit company
  editCompany() {
    this.editCompanyActive = true;
    this.enableFields();
  }

  //enable fields company
  enableFields() {
    Object.keys(this.companyForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.companyForm.get(controlName)?.enable() : null;
    })
  }

  //update company
  updateCompany() {
    Object.keys(this.companyForm.controls).forEach(controlName => {
      const controlValue = this.companyForm.get(controlName)?.value;
      const type = this.config.fields.company.find((f: any) => f.controlName === controlName).type;
      this.company[controlName] = type === 'number' ? parseInt(controlValue) : controlValue;
    })
    this.companyService.updateCompany(this.uuid, this.company).subscribe(({ data, loading }) => {
      this.editCompanyActive = false;
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedCompany} });
      this.disableFields();
    });
  }

  //disable fields company
  disableFields() {
    Object.keys(this.companyForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.companyForm.get(controlName)?.disable() : null;
    })
  }

  //add address dialog
  openAddAddressDialog(): void {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        type: 'addAddressToCompany',
        headline: this.config.dialog.selectAddress,
        text: null,
        element: 'element',
        cancel: this.config.dialog.cancel,
        confirm: this.config.dialog.confirm,
        onAutocompleteChange: (event: any) => {
          this.filterConfig.filter_term = event.target.value;
          this.getAddresses();
        },
        onAutocompleteSelected: (event: any) => {
          this.addressesService.addressToCompanyDisablingCheckboxes(event.option.value)
        }
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) {
        this.addAddress(
          this.addressesService.addressToCompanyForm.get('delivery')?.value, 
          this.addressesService.addressToCompanyForm.get('invoice')?.value, 
          this.addressesService.addressToCompanyForm.get('selectedAddress')?.value.uuid
        );
      }
    });
  }

  //delete address dialog
  openDeleteAddressDialog(address: any): void {
    this.addressToDelete = address;
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        type: 'delete',
        text: `${this.config.dialog.deleteAddress} '${address.street} ${address.house_number}'?`,
        element: address,
        cancel: this.config.dialog.deleteCancel,
        confirm: this.config.dialog.deleteConfirm
      },
    });
    dialogRef.closed.subscribe(async (el: any) => {
      if (el) {
        if (el.invoice) {
          await this.deleteAddress(el, 'invoice');
        }
        if (el.delivery) {
          await this.deleteAddress(el, 'delivery');
        }
      }
    });
  }

  async deleteAddress(address: any, typeToDelete: string): Promise<void> {
    this.addressToDelete = address;
    return new Promise((resolve, reject) => {
      this.companyService
        .deleteCompanyAddress(this.uuid, { type: typeToDelete, address_uuid: this.addressToDelete.uuid })
        .subscribe({
          next: () => {
            const deleteIndex = this.addressesService.addressesOfCompany.findIndex(
              (addr: AddressType) => addr.uuid === this.addressToDelete.uuid && addr.type === typeToDelete
            );
            if (
              this.addressIsInUse(this.addressToDelete.uuid, 'delivery') &&
              this.addressIsInUse(this.addressToDelete.uuid, 'invoice')
            ) {
              this.availableAddresses.push(this.addressesService.addressesOfCompany[deleteIndex]);
            }
            this.addressesService.addressesOfCompany.splice(deleteIndex, 1);
            this.dataSourceAddresses = new MatTableDataSource<Address>(this.addressesService.addressesOfCompany);  
            this._snackBar.openFromComponent(SnackbarTemplateComponent, {
              data: { button: this.config.snackbar.confirm, message: this.config.snackbar.removedAddress },
            });
            this.addressToDelete = '';
            resolve();
          },
          error: (err) => {
            reject(err);
          },
        });
    });
  }

  //address
  addressIsInUse(addrUuid: string, type: string) {
    return (
      this.addressesService.addressesOfCompany.findIndex((addr: any) => {
        return addr.uuid === addrUuid && addr[type] === true;
      }) !== -1
    );
  }

  //add person dialog
  openAddPersonDialog(): void {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        type: 'addPersonToCompany',
        headline: this.config.dialog.selectPerson,
        text: null,
        element: 'element',
        cancel: this.config.dialog.cancel,
        confirm: this.config.dialog.confirm,
        onAutocompleteChange: (event: any) => {
          this.filterConfig.filter_term = event.target.value;
          this.getPersons();
        },
      },
    });
    dialogRef.closed.subscribe((el) => {
      if (el) {
        this.addPerson();
      }
    });
  }

  //add person
  addPerson() {
    this.companyService
      .saveCompanyPerson(this.uuid, { person_uuid: this.personsService.personToCompanyForm.get('selectedPerson')?.value.uuid })
      .subscribe(({ data, loading }) => {
        this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.addedPerson } });
      });
  }

  //delete person dialog
  openDeletePersonDialog(person: any): void {
    this.personToDelete = person;
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        type: 'delete',
        text: `${this.config.dialog.deletePerson} '${person.first_name} ${person.last_name}'?`,
        element: person,
        cancel: this.config.dialog.deleteCancel,
        confirm: this.config.dialog.deleteConfirm
      },
    });
    dialogRef.closed.subscribe((el) => {
      this.personToDelete = '';
      if (el) this.deletePerson(el);
    }); 
  }

  //delete person
  deletePerson(personToDelete: any) {
    this.companyService.deleteCompanyPerson(this.uuid, personToDelete.uuid).subscribe(() => {
      const deleteIndex = this.personsOfCompany.findIndex((person: Person) => {
        return person.uuid === personToDelete.uuid;
      });
      this.personsService.availablePersons.push(this.personsOfCompany[deleteIndex]);
      this.personsOfCompany.splice(deleteIndex, 1);
      this.dataSourcePersons = new MatTableDataSource<Person>(this.companyPersons);
      this.deletePersonOverlay = false;
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.removedPerson } });
      this.personToDelete = '';
    });
  }

  //filter
  applyFilter(event: KeyboardEvent, dataSource: MatTableDataSource<any>) {
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      dataSource.filter = '';
    } else {
      const filterValue = (event.target as HTMLInputElement).value;
      dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  //filter
  deleteValue(value: string, dataSource: MatTableDataSource<any>) {
    dataSource.filter = '';
    switch (value) {
      case 'addresses':
        this.valueFilterAdresses = '';
        break;
      case 'persons':
        this.valueFilterPersons = '';
        break;
    }
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

  openEditPersonDialog(element: any) {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        type: 'editPersonOfCompany',
        headline: 'edit person',
        text: null,
        element: element,
        cancel: this.config.dialog.cancel,
        confirm: this.config.dialog.confirm,
      },
    });
    dialogRef.closed.subscribe((el: any) => {
      if (el) {
        const companyValues = this.personsService.editPersonOfCompanyForm.value;
        this.companyService.updateCompanyPerson(
          this.uuid,
          {
            person_uuid: el.uuid,
            ...companyValues
          }
        ).subscribe(({ data, loading }) => {
          this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedPerson } });
        });
      }
    });
  }

  openEditAddressDialog(element: any) {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        type: 'editAddressOfCompany',
        text: `Edit '${element.street}'`,
        element: element,
        cancel: this.config.dialog.cancel,
        confirm: this.config.dialog.confirm,
      },
    });
    dialogRef.closed.subscribe(async (el: any) => {
      if (el) {
        const editedAddress = this.addressesService.editAddressOfCompanyForm.value;
        if (!editedAddress['delivery']) {  
          await this.deleteAddress(el, 'delivery');
        }
        if (!editedAddress['invoice']) {
          await this.deleteAddress(el, 'invoice');
        }
        if (editedAddress['invoice'] || editedAddress['delivery']) {
          this.addAddress(editedAddress['delivery'], editedAddress['invoice'], el['uuid']);
        }
      }
    });
  }

  addAddress(delivery: any, invoice: any, uuid: string) {
    this.companyService
      .saveCompanyAddress(this.uuid, {
        type_delivery: delivery,
        type_invoice: invoice,
        address_uuid: uuid,
      })
      .subscribe(({ data, loading }) => {
        this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.addedAddress } });
      });
  }

  getEntityFromForm(form: FormGroup, autocompleteField: string) {
    const formValues = form.getRawValue();
    // for autocomplete inputs the complete entity is saved as the value --> overwrite the field with the actual value before saving.
    // see console log
    if (typeof formValues[autocompleteField] === 'object')
      formValues[autocompleteField] = formValues[autocompleteField][autocompleteField];
    return {
      ...formValues
    }
  }
}