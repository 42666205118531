<div class="flex flex-col">
  <div class="text-primary text-lg mb-2">Items</div>
  <div class="h-full invoice-accordion">
    <form class="flex flex-col items-center" [formGroup]="financialService.lineItemsForm">
      <div class="flex justify-center items-center flex-col" (cdkDropListDropped)="drop($event)" formArrayName="lineItems" cdkDropList>
        <div
          class="draggable-element !shadow-none mb-2"
          *ngFor="let item of financialService.lineItems.controls; let i = index; let last = last"
          [formGroupName]="i"
          cdkDrag
        >
          <div class="grid grid-cols-1 lg:grid-cols-12 gap-2" #panel >
            <div class="flex lg:col-start-1">
              <div><span *ngIf="i < 9">0</span>{{ i + 1 }}.</div>
              <mat-icon class="ml-1 cursor-grab" (click)="$event.stopPropagation()" cdkDragHandle>drag_handle</mat-icon>
              <mat-icon
                  *ngIf="financialService.lineItems.controls.length > 1"
                  class="cursor-pointer text-lg flex justify-center items-center"
                  (click)="deleteLineItem(i); $event.stopPropagation()"
                  >delete</mat-icon
                >
            </div>
            <mat-form-field class="mat-form-field-normal w-full !mb-0 lg:row-start-1 lg:col-start-2 lg:col-span-3 !text-sm" appearance="outline">
              <mat-label>Title</mat-label>
              <input
                formControlName="title"
                [matAutocomplete]="lineItemsAuto"
                (keyup)="onProductChange($event, i)"
                matInput
              />
              <mat-autocomplete
                #lineItemsAuto="matAutocomplete"
                (optionSelected)="onProductSelected($event, i)"
                panelWidth="auto"
              >
                <mat-option *ngFor="let product of filteredProducts" [value]="product.title">{{ product.title }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <div class="flex relative w-full lg:col-start-5 lg:col-span-3 2xl:col-span-4 !mb-0">
              <mat-form-field class="w-full !text-sm !bg-background !mb-0" [ngClass]="{'mat-form-field-textarea !absolute z-10': financialService.expandedDescription === i, 'mat-form-field-textarea-thin': financialService.expandedDescription !== i}" (click)="$event.stopPropagation(); financialService.expandDescription(i)" appearance="outline">
                <mat-label>Description</mat-label>
                <textarea class="!resize-none" (keydown)="onInputDescriptionChange($event)" [ngClass]="{'!mt-2': financialService.expandedDescription === i}" formControlName="description" matInput></textarea>
              </mat-form-field>
            </div>

            <div class="w-full xs:flex gap-2 lg:row-start-1 lg:col-start-8 lg:col-span-5 2xl:col-start-9 2xl:col-span-4">
              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>Amount</mat-label>
                <input [formControlName]="'quantity'" (input)="calculateService.calcSums(i, 'quantity')" matInput />
              </mat-form-field>

              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>UP</mat-label>
                <input [formControlName]="'unitPrice'" (input)="calculateService.calcSums(i, 'unitPrice')" matInput />
              </mat-form-field>

              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>VAT</mat-label>
                <input [formControlName]="'taxPerc'" (input)="calculateService.calcSums(i, 'taxPerc')" matInput />
              </mat-form-field>

              <mat-form-field class="hide-field mat-form-field-normal w-full !mb-0 !text-sm" appearance="outline">
                <mat-label>Sum</mat-label>
                <input [formControlName]="'singleSum'" (input)="calculateService.calcSums(i, 'singleSum')" matInput />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div >
    <button (click)="addContainer()" mat-button color="primary" class="!shadow-none text-sm">Add New Item</button>
  </div>

</div>
