import { Component, OnInit, QueryList, ViewChild, ViewChildren, HostListener } from '@angular/core';
import { InvoiceService } from '../../services/finance/invoice.service';
import { NgIf, NgClass, NgFor, DatePipe, DecimalPipe } from '@angular/common';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort, SortDirection } from '@angular/material/sort';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { Dialog, DialogModule } from '@angular/cdk/dialog';
import { DialogTemplateComponent } from '../../components/dialog-template/dialog-template.component';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { StatusService } from '../../services/finance/status.service';
import { status } from '../../interfaces/status';
import { SnackbarTemplateComponent } from '../../components/snackbar-template/snackbar-template.component';
import { FinancialService } from '../../services/finance/financial.service';
import { ProjectSettings } from 'src/assets/config/project-config';
import { defaultInvoiceConfig, FilterConfig } from 'src/app/interfaces/filter-config';

@Component({
  selector: 'financehub-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    NgIf,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    NgClass,
    MatPaginatorModule,
    NgFor,
    MatIconModule,
    DatePipe,
    DialogModule,
    MatTabsModule,
    MatToolbarModule,
    DecimalPipe,
    MatTooltipModule,
    MatSnackBarModule,
  ],
})
export class InvoiceListComponent implements OnInit {
  constructor(
    public invoiceService: InvoiceService,
    public statusService: StatusService,
    public financialService: FinancialService,
    private datePipe: DatePipe,
    private router: Router,
    public dialog: Dialog,
    private _snackBar: MatSnackBar,
  ) {}

  // @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('matTabGroup') matTabGroup!: MatTabGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;



  displayedColumns: string[] = ['invoice_number', 'invoice_date', 'due_date', 'subtotal', 'gross', 'customer_id', 'status', 'icon'];
  dataSources: { [key: string]: MatTableDataSource<any> } = {};

  allInvoices: any = {};
  notSwitching = true;
  config = ProjectSettings;
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  filterConfig: FilterConfig = defaultInvoiceConfig;
  pageIndex!: number;
  sortState: Sort = { active: 'invoice_number', direction: 'desc' };
  filterTerm = '';

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.fetchInvoices();
      }
    });
    this.fetchInvoices();
  }

  fetchInvoices() {
    this.invoiceService.getInvoices(this.filterConfig).subscribe(({ data, loading }) => {
      //NEW UI START
      // const allInvoices = data.invoicesByStatus
      // .map((statusGroup: any) => 
      //   statusGroup.invoices.map((invoice: any) => ({
      //     ...invoice,
      //     status: statusGroup._id
      //   }))
      // )
      // .flat();
      this.dataSource.data = data.invoices.data;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      
      setTimeout(() => {
        if (this.dataSource.paginator) {
          this.dataSource.paginator.length = data.invoices.totalCount;
          this.dataSource.paginator.pageIndex = this.pageIndex;
        }
      });

      //NEW UI END
      // this.statusService.invoiceStatuses.forEach((status: status, index: number) => {
      //   const invoicesByStatus = data.invoicesByStatus.find((i: any) => i._id === status.type);
      //   if (invoicesByStatus) {
      //     this.initDataTables(invoicesByStatus, index);
      //   } else {
      //     this.dataSources[status.type] = new MatTableDataSource<any>([]);
      //     this.dataSources[status.type].paginator = this.paginator.toArray()[index];
      //     this.dataSources[status.type].sort = this.sort.toArray()[index];
      //   }
      // });
    });
  }

  resetPaginationAndRefetch() {
    if (this.dataSource.paginator)
      this.dataSource.paginator.pageIndex = 0;
    this.refetchInvoices();
  }

  clearFilterAndRefetch() {
    this.filterTerm = '';
    this.refetchInvoices();
  }

  refetchInvoices(event?: any) {
    event?.key === 'Escape' && (this.clearFilterAndRefetch());
    if (this.dataSource.paginator && this.dataSource.sort) {
      this.pageIndex = this.dataSource.paginator.pageIndex;
      this.filterConfig = {
        limit_count: this.dataSource.paginator.pageSize,
        limit_start: this.dataSource.paginator.pageSize * this.dataSource.paginator.pageIndex,
        sort_field: this.dataSource.sort.active,
        sort_order: this.dataSource.sort.direction,
        filter_term: this.filterTerm,
      };
      this.fetchInvoices();
    }
  }

  initDataTables(items: any, index: number) {
    // this.allInvoices[items._id] = items.invoices;
    // this.financialService.filterValues[items._id] = '';
    // this.dataSources[items._id] = new MatTableDataSource<any>(this.allInvoices[items._id]);
    // this.dataSources[items._id].paginator = this.paginator.toArray()[index];
    // this.sortTable(this.dataSources[items._id], this.sort.toArray()[index]);
    // const updatedData = this.dataSources[items._id].data.map((item) => ({
    //   ...item,
    //   invoice_date_formatted: this.datePipe.transform(new Date(parseInt(item.invoice_date))),
    //   due_date_formatted: this.datePipe.transform(new Date(parseInt(item.due_date))),
    //   total_sum: item.subtotal + item.total_taxes,
    //   name: item.customer ? item.customer.name_company : '',
    // }));
    // this.dataSources[items._id].data = updatedData;
  }

  /* sortTable(dataSource: MatTableDataSource<any>, sort: MatSort) {
    dataSource.sort = sort;
    dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'number':
          return item.invoice_number;
        case 'invoiceDate':
          return item.invoice_date;
        case 'dueDate':
          return item.due_date;
        case 'net':
          return item.subtotal;
        case 'gross':
          return item.subtotal;
        case 'customerID':
          return item.customer_id;
        default:
          return item[property];
      }
    };
    const sortState: Sort = { active: 'invoiceDate', direction: 'desc' };
    dataSource.sort.active = sortState.active;
    dataSource.sort.direction = sortState.direction;
    dataSource.sort.sortChange.emit(sortState);
  } */

  openDeleteDialog(invoice: any): void {
    const dialogRef = this.dialog.open<string>(DialogTemplateComponent, {
      width: '500px',
      data: {
        type: 'delete',
        text: `${this.config.dialog.deleteInvoice} '${invoice.invoice_number}'?`,
        element: invoice,
        cancel: this.config.dialog.deleteCancel,
        confirm: this.config.dialog.deleteConfirm
      },
    });

    dialogRef.closed.subscribe((invoice) => {
      if (invoice) this.deleteInvoice(invoice);
    });
  }

  deleteInvoice(invoice: any) {
    this.invoiceService.deleteInvoice(invoice._id).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: 'OK', message: this.config.snackbar.deletedInvoice } });
    });
  }

  downloadPdf(invoice: any) {
    this.invoiceService.getInvoicePdf(invoice._id).subscribe({
      next: (response: Blob) => {
          // Create a URL for the PDF
          const url = window.URL.createObjectURL(response);
      
          // Create a link element and click it to download the file
          const link = document.createElement('a');
          link.href = url;
          link.download = `invoice_${invoice.invoice_number}.pdf`;
          link.click();
      
          // Clean up the URL object after download
          window.URL.revokeObjectURL(url);
      },
      error: (error) => {
          this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: 'An error occured' } })
      }
    });
  }

  statusChanged(value: any, id: string) {
    this.invoiceService.updateStatus(id, value).subscribe(({ data, loading }) => {
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: 'OK', message: this.config.snackbar.statusUpdated } });
    });
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const isInputFocused = document.activeElement instanceof HTMLInputElement;
    const statusCount = this.statusService.invoiceStatuses.length;
    if (!isInputFocused) {
      for (let i = 0; i < statusCount; i++) {
        if (event.key === '' + (i + 1)) this.matTabGroup.selectedIndex = i;
      }
    }
  }

  getTabLabel(status: status, index: number) {
    const statusCount = this.dataSources[status.type]?.data?.length || 0;
    return `${index + 1} - ${status.title} (${statusCount})`;
  }

  availableStatuses(statusType: string) {
    // return this.statusService.invoiceStatuses.filter((s) => s.type !== statusType);
    return this.statusService.invoiceStatuses;

  }
}
