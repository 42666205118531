import { Component} from '@angular/core';
import { NgIf, NgClass } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { AddressListComponent } from '../address/address-list/address-list.component';
import { PersonListComponent } from '../person/person-list/person-list.component';
import { CompanyListComponent } from '../company/company-list/company-list.component';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { PersonDetailComponent } from '../person/person-detail/person-detail.component';
import { AddressDetailComponent } from '../address/address-detail/address-detail.component';
import { CompanyDetailComponent } from '../company/company-detail/company-detail.component';

@Component({
  selector: 'financehub-client-list',
  templateUrl: './client-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    AddressListComponent,
    PersonListComponent,
    CompanyListComponent,
    PersonDetailComponent,
    AddressDetailComponent,
    CompanyDetailComponent,
    DialogModule
  ],
})
export class ClientListComponent {

  constructor(
    public financialService: FinancialService
  ) {

  }
}
