<form (submit)="downloadPdf()" class="flex flex-col mx-[2vw] mt-[1vw]">
    <div class="text-primary font-light w-full mb-5 flex flex-col justify-between px-4">
        <div class="text-center text-xl md:text-3xl mb-1">Time Tracking Export</div>
        <div class="flex flex-col gap-4">
            <mat-form-field class="mat-form-field-normal !mb-0 !text-sm" appearance="outline">
                <mat-label>User</mat-label>
                <input placeholder="User" [matAutocomplete]="auto"  autocomplete="new-password" matInput />
                <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="getUserName"
                    (optionSelected)="onUserSelected($event.option.value)"
                    panelWidth="auto"
                >
                    <mat-option *ngFor="let user of users" [value]="user">{{ user.firstName }} {{ user.lastName }}</mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div class="flex gap-4">
                <mat-form-field appearance="outline" class="mat-form-field-normal !mb-0 !text-sm">
                    <mat-label>Month</mat-label>
                    <mat-select [ngModelOptions]="{standalone: true}" name="selectedMonth" [(ngModel)]="selectedMonth" class="!flex">
                        <mat-option *ngFor="let month of months; index as i" [value]="i + 1">{{ month }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="mat-form-field-normal !mb-0 !text-sm">
                    <mat-label>Year</mat-label>
                    <mat-select [ngModelOptions]="{standalone: true}" name="selectedYear" [(ngModel)]="selectedYear" class="!flex">
                        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <select matNativeControl name="selectedMonth" [(ngModel)]="selectedMonth" class="w-40 h-9 rounded-md !px-2 !border-1 !border-solid !border-gray-300">
                    <option [value]="i" *ngFor="let month of months; index as i">{{ month }}</option>
                </select>
                <select matNativeControl name="selectedYear" [(ngModel)]="selectedYear" class="w-20 h-9 rounded-md !px-2 !border-1 !border-solid !border-gray-300">
                    <option [value]="year" *ngFor="let year of years">{{ year }}</option>
                </select> -->
            </div>
            <button mat-raised-button color="primary" type="submit">
                {{config.button.export}}
            </button>
        </div>
    </div>
</form>