<div class="flex justify-center">
  <div class="w-full">
    
    <div class="flex items-center justify-between px-5 lg:px-0 mb-1">
      <span class="text-xl md:text-3xl text-primary font-light w-full">{{heading}}</span>
      <mat-form-field class="mat-form-field-normal mat-form-field-filter font-medium !text-sm hide-field mr-3 w-3/5 lg:w-2/5 !mb-0" appearance="outline">
        <mat-label>{{config.table.filter}}</mat-label>
        <input [(ngModel)]="filterValue" (ngModelChange)="onFilterChange($event)" (keydown)="checkKey($event)" matInput />
        <mat-icon class="cursor-pointer" *ngIf="filterValue" (click)="deleteFilter($event)" matSuffix>close</mat-icon>
      </mat-form-field>
    </div>

    <div class="mx-5 mb-5 lg:mx-0">
      <div class="overflow-x-auto relative">
        <mat-spinner class="!absolute top-1/2 left-1/2 z-50 transform -translate-x-1/2 -translate-y-1/2 !h-6 !w-6" *ngIf="loading"></mat-spinner>
        <table [dataSource]="dataSource" mat-table matSort [matSortActive]="sortActive" [matSortDirection]="sortDirection">
          <ng-container *ngFor="let c of array">
            <ng-container [matColumnDef]="c" *ngIf="c !== 'actions'">
              <th class="" *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by {{ c }}">{{c}}</th>
              <td class="relative" [ngClass]="{'w-32': c === 'bds_number', '!w-40' : c === 'first_name'}" *matCellDef="let element" mat-cell>
                {{ element[c] }}   
              </td>
            </ng-container>
            <ng-container matColumnDef="actions" *ngIf="c === 'actions'">
              <th *matHeaderCellDef mat-header-cell></th>
              <td *matCellDef="let element" mat-cell>
                <div class="flex flex-col justify-end xs:flex-row">
                  <mat-icon *ngIf="isEditable" class="mr-3 cursor-pointer" (click)="$event.stopPropagation(); editEntity(entity, element.uuid)">edit</mat-icon>
                  <mat-icon class="cursor-pointer" (click)="$event.stopPropagation(); deleteElement(element)"> delete </mat-icon>
                </div>
              </td>
            </ng-container> 
          </ng-container>
          <tr *matHeaderRowDef="array" mat-header-row></tr>
          <tr *matRowDef="let row; let odd = odd; columns: array; let element;" (click)="$event.stopPropagation(); editEntity(entity, element.uuid)" class="cursor-pointer hover:!bg-white" [ngClass]="{ '!bg-background':  (element.uuid !== financialService.entityEditUuid), '!bg-white' : (element.uuid === financialService.entityEditUuid)}" mat-row></tr>
        </table>
      </div>
      <mat-paginator [pageSizeOptions]="config.table.itemsPerPage" showFirstLastButtons aria-label="Select page of periodic elements">
      </mat-paginator>
    </div>
  </div>

</div>
