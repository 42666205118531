export const ProjectSettings = {
    labels: {
        addresses: 'Addresses',
        address: 'Address',
        companies: 'Companies',
        company: 'Company',
        persons: 'Persons',
        person: 'Person'
    },
    button: {
        addAddress: 'Add Address',
        addCompany: 'Add Company',
        addPerson: 'Add Person',
        addOffer: 'Add Quote',
        addInvoice: 'Add Invoice',
        addUser: 'Add User',
        create: 'Save',
        createAddress: 'Create Address',
        createCompany: 'Create Company',
        createPerson: 'Create Person',
        createInvoice: 'Create Invoice',
        createOffer: 'Create Quote',
        createUser: 'Create User',
        editAddress: 'Edit Address',
        editCompany: 'Edit Company',
        editPerson: 'Edit Person',
        saveAddress: 'Save Address',
        saveCompany: 'Save Company',
        savePerson: 'Save Person',
        updateInvoice: 'Update Invoice',
        updateOffer: 'Update Quote',
        reset: 'Reset',
        resetCollection: 'Reset collection',
        edit: 'Edit',
        import: 'Import',
        export: 'Export'
    },
    snackbar: {
        deletedAddress: 'Address Deleted',
        deletedCompany: 'Company Deleted',
        deletedPerson: 'Person Deleted',
        deletedUser: 'User Deleted',
        deletedInvoice: 'Invoice Deleted',
        deletedOffer: 'Quote Deleted',
        createdAddress: 'Address Created',
        createdCompany: 'Company Created',
        createdPerson: 'Person Created',
        createdInvoice: 'Invoice Created',
        createdOffer: 'Quote Created',
        createdClient: 'Client Created',
        createdUser: 'User Created',
        createdTimeTracking: 'Time Tracking Created',
        removedAddress: 'Address removed',
        removedCompany: 'Company removed',
        removedPerson: 'Person removed',
        updatedAddress: 'Address Updated',
        updatedCompany: 'Company Updated',
        updatedPerson: 'Person Updated',
        updatedInvoice: 'Invoice Updated',
        updatedOffer: 'Quote Updated',
        updatedTimeTracking: 'Time Tracking Updated',
        addedPerson: 'Person Added',
        addedAddress: 'Address Added',
        statusUpdated: 'Status Updated',
        incompleteInvoice: 'Invoice Incomplete', 
        incompleteOffer: 'Quote Incomplete',
        incompleteClient: 'Client Incomplete',
        incompleteForm: 'Form Incomplete',
        csvImportSuccessful: 'Csv import successful',
        collectionReset: 'Database collection successfully reset',
        confirm: 'OK'
    },
    dialog: {
        deleteAddress: 'Delete Address',
        deleteCompany: 'Delete Company',
        deletePerson: 'Delete Person',
        deleteInvoice: 'Delete Invoice',
        deleteOffer: 'Delete Quote',
        deleteUser: 'Delete User',
        deleteCancel: 'No, not delete',
        deleteConfirm: 'Yes, delete',
        selectAddress: 'Select Address',
        selectCompany: 'Select Company',
        selectPerson: 'Select Person',
        createConfirm: 'Yes, create',
        createCancel: 'No, not create',
        cancel: 'Cancel',
        confirm: 'OK'
    },
    fields: {
        company: [
            {name: 'UUID', controlName: 'uuid', type: 'text', readonly: true, validation: true, class: 'row-start-1 col-span-6 lg:col-span-9 2xl:col-span-8'},
            {name: 'BDS Nr.', controlName: 'bds_number', type: 'number', validation: true, class: 'row-start-1 col-start-10 col-span-3'},
            {name: 'Name', controlName: 'name_company', type: 'autocomplete', placeholder: 'My Company', validation: true, class: 'row-start-2 col-span-12 2xl:col-span-6'},
            {name: 'URL', controlName: 'url', type: 'link', placeholder: 'www.google.com', class: 'row-start-3 col-span-12 2xl:row-start-2 2xl:col-span-6'},
            {name: 'Info', controlName: 'info', type: 'textarea', class: 'row-start-4 col-span-12 2xl:row-start-3'},
            {name: 'Bankname', controlName: 'bank_name', type: 'text', placeholder: 'Royal Bank', class: 'row-start-5 col-start-1 col-span-6 2xl:row-start-4'},
            {name: 'BIC', controlName: 'bic', type: 'text', placeholder: 'GIBAATWWXXX',  class: 'row-start-5 col-start-7 col-span-6 2xl:row-start-4'},
            {name: 'IBAN', controlName: 'iban', type: 'text', placeholder: 'AT121234500234567891', class: 'row-start-6 col-start-1 col-span-12 2xl:row-start-5 2xl:col-span-6'},
            {name: 'UID', controlName: 'uid', type: 'text', placeholder: 'ATU12345678',  class: 'row-start-7 col-span-5 2xl:row-start-6 2xl:col-span-4'},
            {name: 'CRN', controlName: 'company_register_number', type: 'text', placeholder: 'FN 123456a', class: 'row-start-7 col-start-8 col-span-5 2xl:row-start-6 2xl:col-start-9 2xl:col-span-4'},
        ],
        address: [
            {name: 'UUID', controlName: 'uuid', type: 'text', readonly: true, validation: true, class: 'col-span-6 lg:col-span-9 2xl:col-span-8'},
            {name: 'BDS Nr.', controlName: 'bds_number', type: 'number', validation: true, class: 'col-start-10 col-span-3'},
            {name: 'Street', controlName: 'street', type: 'autocomplete', placeholder: 'Maple Street', class: 'col-span-6', validation: true},
            {name: 'Nr.', controlName: 'house_number', type: 'text', placeholder: '12', class: 'col-span-2', validation: true },
            {name: 'Info', controlName: 'additional_information', type: 'text', placeholder: 'Stair B, 3F, D5', class: 'col-span-4'},
            {name: 'Zip', controlName: 'zip', type: 'text', placeholder: '1100', class: 'col-span-3', validation: true},
            {name: 'City', controlName: 'city', type: 'text', placeholder: 'London', class: 'col-span-5 lg:col-span-4', validation: true},
            {name: 'Country', controlName: 'country', type: 'select',  optionsArray: [
                'Austria', 'Germany', 'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina', 'Armenia', 'Australia',
                'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin',
                'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana', 'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi',
                'Cabo Verde', 'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia',
                'Comoros', 'Congo (Congo-Brazzaville)', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czechia (Czech Republic)',
                'Democratic Republic of the Congo', 'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt',
                'El Salvador', 'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini (fmr. "Swaziland")', 'Ethiopia', 'Fiji', 'Finland',
                'France', 'Gabon', 'Gambia', 'Georgia', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea',
                'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See', 'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran',
                'Iraq', 'Ireland', 'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati',
                'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania',
                'Luxembourg', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania',
                'Mauritius', 'Mexico', 'Micronesia', 'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar',
                'Namibia', 'Nauru', 'Nepal', 'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea',
                'North Macedonia (formerly Macedonia)', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine State', 'Panama',
                'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda',
                'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines', 'Samoa', 'San Marino', 'Sao Tome and Principe',
                'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone', 'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands',
                'Somalia', 'South Africa', 'South Korea', 'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland',
                'Syria', 'Tajikistan', 'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey',
                'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom', 'United States', 'Uruguay',
                'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela', 'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
            ],
            class: 'col-span-4 lg:col-span-5', validation: true},
        ],
        person: [
            {name: 'UUID', controlName: 'uuid', type: 'text', readonly: true, validation: true, class: 'col-span-6 lg:col-span-9 2xl:col-span-8'},
            {name: 'BDS Nr.', controlName: 'bds_number', type: 'number', validation: true, class: 'col-start-10 col-span-3'},
            {name: 'First Name', controlName: 'first_name', type: 'autocomplete', placeholder: 'Jane Maxine', validation: true, class: 'col-span-6 2xl:col-span-4'},
            {name: 'Last Name', controlName: 'last_name', type: 'text', placeholder: 'Doe', validation: true, class: 'col-span-6 2xl:col-span-4'},
            {name: 'Gender', controlName: 'gender', type: 'select', optionsArray: ['female', 'male', 'other'], class: 'col-span-6 2xl:col-span-4'},
            {name: 'Title Before', controlName: 'title_academic', type: 'text', placeholder: 'Dr., Prof.', class: 'row-start-4 col-span-6 2xl:row-start-3'},
            {name: 'Title After', controlName: 'title_academic_after', placeholder: 'Ph.D., M.D.', type: 'text', class: 'row-start-4 col-span-6 2xl:row-start-3'},
            {name: 'Fax Private', controlName: 'fax', type: 'text', placeholder: '+43123456789', class: 'col-span-6'},
            {name: 'Phone Private', controlName: 'phone_private', type: 'text', placeholder: '+436641234567', class: 'col-span-5 lg:col-span-6'},
            {name: 'Email Private', controlName: 'mail_private', type: 'email', placeholder: 'max.mustermann@mail.com', class: 'col-span-7 lg:col-span-12'},
            {name: 'Birthday', controlName: 'birthday', type: 'datepicker', placeholder: '19.03.1985', class: 'col-span-12 lg:col-span-6'},
        ],
        personCompanyInfo: [
            {name: 'Position', controlName: 'position', type: 'text', placeholder: 'Office Manager, IT Manager', class: 'col-span-6 lg:col-span-6'},
            {name: 'Fax Office', controlName: 'fax_office', type: 'text', placeholder: '+43123456789', class: 'col-span-6 lg:col-span-6'},
            {name: 'Phone Office', controlName: 'phone_office', type: 'text', placeholder: '+436641234567', class: 'col-span-12 lg:col-span-6'},
            {name: 'Phone Mobile', controlName: 'phone_mobile', type: 'text', placeholder: '+436641234567', class: 'col-span-12 lg:col-span-6'},
            {name: 'Email Office', controlName: 'email_office', type: 'email', placeholder: 'max.mustermann@mail.com', class: 'col-span-12'},
        ],
        addressType: [
            {name: 'Billing Address', controlName: 'invoice', type: 'checkbox', class: 'col-span-12', defaultValue: true},
            {name: 'Shipping Address', controlName: 'delivery', type: 'checkbox', class: 'col-span-12', defaultValue: true},
        ],
        user: [
            {name: 'Username', controlName: 'username', type: 'text', placeholder: 'Jane2024', validation: true, class: "col-span-12 lg:col-span-4"},
            {name: 'First Name', controlName: 'firstName', type: 'text', placeholder: 'Jane Maxine', validation: true, class: "col-span-12 lg:col-span-4"},
            {name: 'Last Name', controlName: 'lastName', type: 'text', placeholder: 'Doe', validation: true, class: "col-span-12 lg:col-span-4"},
            {name: 'E-Mail', controlName: 'email', type: 'email', placeholder: 'jane.doe@gmail.com', validation: true, class: "col-span-12 lg:col-span-6"},
            {name: 'Password', controlName: 'password', type: 'password', validation: true, class: "col-span-12 lg:col-span-6"},
        ]
    },
    table: {
        filter: 'Filter',
        itemsPerPage: [5,10,30],
        sortBy: 'bds_number',
    },
    tableColumns: {
        companies:  ['bds_number', 'name_company', 'actions'],
        companyAddresses: ['bds_number', 'country', 'zip', 'city', 'street', 'house_number', 'invoice', 'delivery', 'actions'],
        companyPersons: ['bds_number', 'first_name', 'last_name', 'info', 'birthday', 'fax', 'position', 'phone_office', 'phone_mobile', 'email_office','fax_office', 'actions'],
        addresses: ['bds_number', 'street', 'house_number', 'city', 'zip', 'country', 'actions'],
        addressCompanies: ['bds_number', 'name_company'],
        addressPersons: ['bds_number', 'last_name', 'first_name',],
        persons: ['bds_number', 'last_name', 'first_name', 'actions'],
        personCompanies: ['bds_number', 'name_company'],
        personAddresses: [ 'bds_number', 'name', 'country', 'zip', 'city', 'street', 'house_number'],
        users: [ 'firstName', 'lastName', 'username', 'email', 'actions'],
    },
    invoiceProjects: [
        { title: 'no project', coManager: 'Rene Heinzl', number: 100, manager: 'Rene Heinzl' },
        { title: 'Anker', coManager: 'test', number: 101, manager: 'Rene Heinzl' },
        { title: 'Recruiting', coManager: 'test', number: 102, manager: 'Markus Nissl' },
        { title: 'Financecompass', coManager: 'test', number: 103, manager: 'Rene Heinzl' },
        { title: 'A1', coManager: 'test', number: 104, manager: 'Christoph Burger' },
        { title: 'Brantner', coManager: 'test', number: 105, manager: 'Rene Heinzl' },
      ],
    invoiceProducts: [
        {
            title: 'Software Development',
            unitPrice: 1500,
            tax: 20,
            description: 'Design, development, and implementation of customized software solutions.',
        },
        {
            title: 'Cybersecurity',
            unitPrice: 50,
            tax: 10,
            description:
                'Securing IT systems and data from threats, attacks, and unauthorized access. This includes penetration tests, security audits, implementation of firewalls, antivirus software, and employee training.',
        },
        {
            title: 'Network and Infrastructure',
            unitPrice: 199.9,
            tax: 10,
            description:
                'Planning, implementation, and maintenance of networks, servers, storage systems, and other IT infrastructures.',
        },
        {
            title: 'Database Management and Optimization',
            unitPrice: 500,
            tax: 20,
            description:
                'Management of databases, query optimization, data backup, recovery, and scaling to ensure data is efficiently stored, managed, and retrieved.',
        },
        {
            title: 'IT Consulting',
            unitPrice: 3500,
            tax: 15,
            description:
                'Expertise and recommendations regarding technology strategies, infrastructure, process optimization, and technology selection.',
        },
        {
            title: 'Data Analysis and Business Intelligence',
            unitPrice: 10,
            tax: 10,
            description: 'Collection, processing, and analysis of business data.',
        },
        {
            title: 'Mobile App Development',
            unitPrice: 2399.99,
            tax: 20,
            description: '',
        },
        {
            title: 'IT Infrastructure Monitoring and Maintenance',
            unitPrice: 745.9,
            tax: 10,
            description:
                'Ensure continuous monitoring, maintenance, and updating of the IT infrastructure to minimize downtime and maintain performance.',
        }          
    ],
    invoicePreTexts: [
        { title: 'empty', text: '' },
        {
            title: 'Variant 1',
            text: `Dear Sir or Madam,\n\nThank you for your trust in our products.\nWe hope you are satisfied with your purchase and would be delighted to hear from you again. If you have any questions or feedback, please do not hesitate to contact us.`,
        },
        {
            title: 'Variant 2',
            text: `Dear Sir or Madam,\n\nThank you for placing your trust in our products.\nWe hope you are fully satisfied and look forward to serving you again in the future.`,
        },
        {
            title: 'Variant 3',
            text: 'Thank you for the excellent collaboration. As agreed, we are pleased to invoice you for the following deliveries and services:',
        }
    ],
    invoicePostTexts: [
        { title: 'empty', text: '' },
        { title: 'Variant 1', text: 'If you have any questions, please feel free to contact me at any time. I am always happy to assist you!' },
        { title: 'Variant 2', text: 'Thank you very much for your valued order!' },
        { title: 'Variant 3', text: 'I greatly appreciate your excellent cooperation and look forward to continuing our successful partnership.' }
    ]
    

}