import { Component, OnInit } from '@angular/core';
import { ListParentComponent } from 'src/app/components/list-parent/list-parent.component';
import { ListTemplateComponent } from 'src/app/components/list-template/list-template.component';
import { Sort } from '@angular/material/sort';


@Component({
  selector: 'financehub-users',
  templateUrl: './user-list.component.html',
  standalone: true,
  imports: [
    ListTemplateComponent,
    ListParentComponent
  ]
})
export class UserListComponent extends ListParentComponent implements OnInit {

  sortState: Sort = { active: this.config.table.sortBy, direction: 'desc' };

  ngOnInit(): void {
    this.fetchEntities('users');
  } 
}
