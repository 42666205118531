import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.development';

const GET_USER_TIME_TRACKING = gql`
  query userTimeTracking($filter: UserTimeTrackingFilter) {
    userTimeTracking (filter: $filter) {
      user_uuid
      year
      month
      time_trackings {
        day_index
        day_name
        start
        end
        break
        hours_amount
        absence
        note
      }
    }
  }
`;

const CREATE_USER_TIME_TRACKING = gql`
  mutation CreateUserTimeTracking($createInput: CreateUserTimeTrackingInput) {
    createUserTimeTracking(createInput: $createInput) {
      user_uuid
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class UserTimeTrackingService {
  constructor(
    private apollo: Apollo,
    private http: HttpClient,
  ) {}

  getUserTimeTracking(input: any): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_USER_TIME_TRACKING,
      variables: {
        filter: input
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  saveUserTimeTracking(input: any): Observable<any> {
    return this.apollo.mutate({
      mutation: CREATE_USER_TIME_TRACKING,
      variables: { createInput: input },
    });
  }

  getTimeTrackingPdf(data: any) {
    return this.http.post(`${environment.uri}/user-time-trackings/pdf`, data, { responseType: 'blob' })
  }
}
