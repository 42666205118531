<div class="grid grid-cols-12 grid-rows-auto">
  <div class="text-3xl text-primary font-light w-full px-4 col-span-12 md:col-start-4 md:col-span-6 2xl:col-start-5 2xl:col-span-4"><span class="text-start">Create Person</span></div>
  <financehub-add-template 
    class="relative top-10 col-span-12 md:col-start-4 md:col-span-6 2xl:col-start-5 2xl:col-span-4"
    [formGroup]="personForm" 
    [create]="create.bind(this)" 
    [array]="config.fields.person"
    [createButton]="config.button.createPerson"
    [disabledButton]="personForm.invalid"
  />
</div>