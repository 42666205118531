import { NgFor, NgIf } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { InputTemplateComponent } from '../input-template/input-template.component';
import { MatIconModule } from '@angular/material/icon';


@Component({
  selector: 'financehub-detail-template',
  templateUrl: './detail-template.component.html',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    InputTemplateComponent,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule
  ],
}) 
export class DetailTemplateComponent {
  @Input() formGroup!: FormGroup;
  @Input() array!: any[];
  @Input() editButton!: any;
  @Input() saveButton!: any;
  @Input() editActive!: boolean;
  @Input() entity!: string;
  @Input() disabledButton: any;
  @Input() optionsArray: any;
  @Output() clickedUpdate = new EventEmitter();
  @Output() clickedEdit = new EventEmitter();


  updateEntity(event: any) {
    this.clickedUpdate.emit(event)
  }

  editEntity(event: any) {
    this.clickedEdit.emit(event)
  }
}
