<mat-sidenav-container class="h-full">
  <mat-sidenav
    class="w-max"
    #drawer
    attr.role="dialog"
    mode="over"
    opened="false"
    fixedInViewport
  >
    <mat-nav-list class="navigation-list">
      <mat-list-item role="listitem"> <a routerLink="/invoices">Invoices</a></mat-list-item>
      <mat-list-item role="listitem"> <a routerLink="/quotes">Quotes</a></mat-list-item>
      <mat-list-item role="listitem"><a routerLink="/clients">Clients</a></mat-list-item>
      <!-- <mat-list-item role="listitem"><a routerLink="/">Items</a></mat-list-item>
      <mat-list-item role="listitem"><a routerLink="/">Projects</a></mat-list-item> -->
      <mat-list-item role="listitem"><a routerLink="/admin">Admin</a></mat-list-item>
      <mat-list-item role="listitem"><a routerLink="/users">Users</a></mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="!bg-[#F8FAFC]" (click)="financialService.expandedDescription = -1">
    <mat-toolbar class="!bg-[#F8FAFC]">
      <div class="flex justify-between w-full">
        <div class="flex items-center gap-x-2">
          <button class="xl:!hidden" (click)="drawer.toggle()" type="button" aria-label="Toggle sidenav" mat-icon-button>
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
          </button>
          <a class="hidden md:flex !text-3xl text-primary" routerLink="/">Finance Compass</a>
        </div>
        <div class="hidden xl:flex items-center gap-x-10">
          <a routerLink="/invoices">Invoices</a>
          <a routerLink="/quotes">Quotes</a>
          <a routerLink="/clients">Clients</a>
          <!-- <a routerLink="/">Items</a>
          <a routerLink="/">Projects</a> -->
          <a routerLink="/admin">Admin</a>
          <a routerLink="/users">Users</a>
        </div>
        <div class="flex items-center gap-x-2">
          <button mat-flat-button color="primary" [matMenuTriggerFor]="createButton"><span>Create</span><mat-icon>keyboard_arrow_down</mat-icon></button>
          <mat-menu #createButton="matMenu">
            <a routerLink="/address/create"><button mat-menu-item>Address</button></a>
            <a routerLink="/company/create"><button mat-menu-item>Company</button></a>
            <a routerLink="/client/create"><button mat-menu-item>Client</button></a>
            <a routerLink="/invoice/create"><button mat-menu-item>Invoice</button></a>
            <a routerLink="/person/create"><button mat-menu-item>Person</button></a>
            <a routerLink="/quote/create"><button mat-menu-item>Quote</button></a>
            <a routerLink="/timeTracking"><button mat-menu-item>Time Tracking</button></a>
            <a routerLink="/user/create"><button mat-menu-item>User</button></a>
          </mat-menu>
          <button [matMenuTriggerFor]="menu" mat-button class="flex items-center">
            <mat-icon>account_circle</mat-icon>
            <span>{{ usercode }}</span>
          </button>
        </div>
      </div>
      <mat-menu #menu="matMenu">
        <button *ngIf="isLoggedIn" (click)="logout()" mat-menu-item>Sign Out</button>
      </mat-menu>
    </mat-toolbar>

    <div>
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
