import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CalculateService } from 'src/app/services/finance/calculate.service';
import { CompanyService } from 'src/app/services/crm/company.service';
import { Company } from 'src/app/interfaces/company';
import { InputTemplateComponent } from '../input-template/input-template.component';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { ProjectSettings } from 'src/assets/config/project-config';
import { debounceTime, switchMap } from 'rxjs';
import moment from 'moment';
import { defaultConfig, FilterConfig } from 'src/app/interfaces/filter-config';

@Component({
  selector: 'financehub-invoice-details',
  templateUrl: './invoice-details.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    InputTemplateComponent,
  ],
})
export class InvoiceDetailsComponent implements OnInit {
  constructor(
    public calculateService: CalculateService,
    private companyService: CompanyService,
    public financialService: FinancialService,
  ) {
    this.financialService.detailsForm = this.setForm();
  }

  config = ProjectSettings;
  filterConfig: FilterConfig = defaultConfig;
  date!: string;
  days!: string;
  expirationDate!: string;
  expirationDays!: string;

  setForm() {
    return new FormGroup({
      customer: new FormControl('', [Validators.required]),
      inputLocale: new FormControl('de-DE', [Validators.required]),
      outputLocale: new FormControl('de-DE', [Validators.required]),
      ...this.getDates()
    });
  }

  getDates() {
    if (this.financialService.isInvoice) {
      return {
        invoiceDateDays: new FormControl('', [Validators.required]),
        invoiceDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
        dueDateDays: new FormControl('', [Validators.required]),
        dueDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
      }
    } else {
      return {
        offerDateDays: new FormControl('', [Validators.required]),
        offerDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
        validityDateDays: new FormControl('', [Validators.required]),
        validityDate: new FormControl({ value: '', disabled: true }, [Validators.required]),
      }
    }
  }

  ngOnInit() {
    this.getStrings();
    this.subscribeDateFields();
    this.companyService.getCompanies(this.filterConfig).subscribe(({ data, loading }) => {
      this.financialService.filteredCompaniesOriginal = data.companies.data;
      this.financialService.filteredCompanies = [...data.companies.data];
      this.setDates();
      this.sortCompanies();
    });
  }

  getStrings() {
    this.date = this.financialService.isInvoice ? 'invoiceDate' : 'offerDate';
    this.days = this.financialService.isInvoice ? 'invoiceDateDays' : 'offerDateDays';
    this.expirationDate = this.financialService.isInvoice ? 'dueDate' : 'validityDate';
    this.expirationDays = this.financialService.isInvoice ? 'dueDateDays' : 'validityDateDays';
  }

  subscribeDateFields() {
    this.financialService.detailsForm
      .get(this.expirationDays)
      ?.valueChanges.pipe(
        debounceTime(200),
        switchMap((changedValue) => this.changeExpirationDate(changedValue)),
      )
      .subscribe();
    this.financialService.detailsForm
      .get(this.days)
      ?.valueChanges.pipe(
        debounceTime(200),
        switchMap((changedValue) => this.changeDate(changedValue)),
      )
      .subscribe();
  }

  async changeDate(inputValue: string, isInit = false) {
    if (isInit) this.financialService.detailsForm.get(this.days)?.setValue('today');
    if (inputValue.startsWith('tod')) {
      this.financialService.detailsForm.get(this.date)?.setValue(moment().format('DD.MM.yyyy'));
    } else if (inputValue.startsWith('tom')) {
      this.financialService.detailsForm.get(this.date)?.setValue(moment().add('1', 'days').format('DD.MM.yyyy'));
    } else {
      this.financialService.detailsForm.get(this.date)?.setValue(moment().add(inputValue, 'days').format('DD.MM.yyyy'));
    }
    this.changeExpirationDate(this.financialService.detailsForm.get(this.expirationDays)?.value);
  }

  async changeExpirationDate(inputValue: string, isInit = false) {
    const invoiceDate = this.financialService.detailsForm.get(this.date)?.value;
    if (isInit) this.financialService.detailsForm.get(this.expirationDays)?.setValue(inputValue);
    const dueDate = moment(invoiceDate, 'DD.MM.yyyy').add(inputValue, 'days');
    this.financialService.detailsForm.get(this.expirationDate)?.setValue(dueDate.format('DD.MM.yyyy'));
  }

  setDates() {
    if (!this.financialService.isEdit) {
      this.changeExpirationDate('14', true);
      this.changeDate('tod', true);
    }
  }

  sortCompanies() {
    const sortedArray = this.financialService.filteredCompanies.sort((a, b) => {
      const nameA = a.name_company.toUpperCase();
      const nameB = b.name_company.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  autocompleteChanged(event: KeyboardEvent) {
    let inputValue = '';
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.financialService.detailsForm.get('customer')?.setValue('');
    }
    inputValue = (event.target as HTMLInputElement).value;
    this.companyService.getCompanies({ ...this.filterConfig, filter_term: inputValue }).subscribe(({ data, loading }) => {
      this.financialService.filteredCompaniesOriginal = data.companies.data;
      this.financialService.filteredCompanies = [...data.companies.data];
      this.sortCompanies();
    });
  }

  onCompanySelected(event: MatAutocompleteSelectedEvent) {
    this.financialService.customerForm.reset();
    const selectedCustomer = event.option.value;
    this.financialService.filteredInvoiceAddresses = [...selectedCustomer.invoice_addresses];
    this.financialService.filteredInvoiceAddressesOriginal = selectedCustomer.invoice_addresses;
    this.financialService.filteredDeliveryAddresses = [...selectedCustomer.delivery_addresses];
    this.financialService.filteredDeliveryAddressesOriginal = selectedCustomer.delivery_addresses;
    this.financialService.filteredPersons = [...selectedCustomer.persons];
    this.financialService.filteredPersonsOriginal = selectedCustomer.persons;
    this.financialService.customerForm.get('bds_number')?.setValue(selectedCustomer.bds_number);
    this.financialService.customerForm.get('company_register_number')?.setValue(selectedCustomer.company_register_number);
    this.financialService.customerForm.get('uid')?.setValue(selectedCustomer.uid);
    this.financialService.customerForm.get('uuid')?.setValue(selectedCustomer.uuid);
    this.sortAddresses(this.financialService.filteredInvoiceAddresses);
    this.sortAddresses(this.financialService.filteredDeliveryAddresses);
    this.setDefaultAddresses();
  }

  sortAddresses(array: any[]) {
   array.sort((a, b) => {
      const nameA = a.street.toUpperCase();
      const nameB = b.street.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  setDefaultAddresses() {
    if (this.financialService.filteredInvoiceAddresses.length > 0) {
      const address = this.financialService.filteredInvoiceAddresses[0];
      this.financialService.setInvoiceAddressValues(address);
    }
    if (this.financialService.filteredDeliveryAddresses.length > 0) {
      const address = this.financialService.filteredDeliveryAddresses[0];
      this.financialService.setDeliveryAddressValues(address);
    }
  }

  displayCustomerName(customer: Company) {
    return customer ? customer.name_company : '';
  }
}
