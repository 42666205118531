import { Component } from '@angular/core';
import { InvoiceService } from '../../services/finance/invoice.service';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { NgIf } from '@angular/common';
import { InputTemplateComponent } from '../input-template/input-template.component';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FinancialService } from '../../services/finance/financial.service';
import { ProjectSettings } from 'src/assets/config/project-config';

@Component({
  selector: 'financehub-invoice-project',
  templateUrl: './invoice-project.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    InputTemplateComponent
  ],
})
export class InvoiceProjectComponent {
  constructor(
    public invoiceService: InvoiceService,
    public financialService: FinancialService,
  ) {
    this.financialService.projectForm = this.financialService.setProjectForm();
  }

  projectForm!: FormGroup;
  filteredProjects!: any[];
  config = ProjectSettings;

  ngOnInit() {
    this.filteredProjects = this.config.invoiceProjects;
    this.sortProjects();
  }

  sortProjects() {
    const sortedArray = this.filteredProjects.sort((a, b) => {
      const nameA = a.title.toUpperCase();
      const nameB = b.title.toUpperCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  onProjectChange(event: KeyboardEvent) {
    let inputValue = '';
    if (event.key == 'Escape') {
      (event.target as HTMLInputElement).value = '';
      this.financialService.projectForm.get('search')?.setValue('');
    }
    inputValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredProjects = this.config.invoiceProjects.filter((p) =>
      p.title.toLowerCase().includes(inputValue.toLowerCase()),
    );
  }

  onProjectSelected(event: MatAutocompleteSelectedEvent) {
    const project = event.option.value;
    this.financialService.projectForm.get('search')?.setValue(project.title);
    this.financialService.projectForm.get('title')?.setValue(project.title);
    this.financialService.projectForm.get('number')?.setValue(project.number);
    this.financialService.projectForm.get('manager')?.setValue(project.manager);
    this.financialService.projectForm.get('coManager')?.setValue(project.coManager);
  }
}
