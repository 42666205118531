<div>
  <div class="text-primary text-lg mb-2">Message</div>
  <div class="flex justify-center">
    <form
      class="mb-4 flex grid w-full grid-cols-2 gap-x-2 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-1 2xl:flex-col"
      [formGroup]="financialService.textsForm"
    >
      <div class="flex flex-col">
        <financehub-input-template
          *ngIf="!financialService.isEdit"
          [optionsArray]="config.invoicePreTexts"
          [formGroup]="financialService.textsForm"
          (selectionChanged)="onTextBeforeSelected($event.value)"
          type="select"
          label="Text Before"
          controlName="textBeforeTitle"
          optionValue="title"
        />
        <financehub-input-template
          [formGroup]="financialService.textsForm"
          label="Text Before"
          controlName="textBefore"
          type="textarea"
        />
      </div>
      <div class="flex flex-col">
        <financehub-input-template
          *ngIf="!financialService.isEdit"
          [optionsArray]="config.invoicePreTexts"
          [formGroup]="financialService.textsForm"
          (selectionChanged)="onTextAfterSelected($event.value)"
          type="select"
          label="Text After"
          controlName="textAfterTitle"
          optionValue="title"
        />
        <financehub-input-template
          [formGroup]="financialService.textsForm"
          label="Text After"
          controlName="textAfter"
          type="textarea"
        />
      </div>
    </form>
  </div>
</div>