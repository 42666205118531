import { Component, Inject, OnInit } from '@angular/core';
import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AddressesService } from '../../services/crm/addresses.service';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PersonsService } from '../../services/crm/persons.service';
import { Person } from 'src/app/interfaces/person';
import { InputTemplateComponent } from '../input-template/input-template.component';
import { Address } from 'src/app/interfaces/address';
import { ProjectSettings } from 'src/assets/config/project-config';

@Component({
  selector: 'financehub-dialog-template',
  templateUrl: './dialog-template.component.html',
  standalone: true,
  imports: [
    FormsModule,
    MatButtonModule,
    NgIf,
    MatFormFieldModule,
    MatSelectModule,
    NgForOf,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    InputTemplateComponent,
  ],
})
export class DialogTemplateComponent implements OnInit{
  constructor(
    public addressesService: AddressesService,
    public personsService: PersonsService,
    public dialogRef: DialogRef<string>,
    @Inject(DIALOG_DATA) public data: any
  ) {}

  config = ProjectSettings;

  ngOnInit() {
    if (this.data.type === 'editPersonOfCompany') {
      this.personsService.setEditPersonOfCompanyForm(this.data.element);
    } else if (this.data.type === 'addAddressToCompany') {
      this.addressesService.setAddressToCompanyForm();
    } else if (this.data.type === 'addPersonToCompany') {
      this.personsService.setPersonToCompanyForm();
    } else if (this.data.type === 'editAddressOfCompany') {
      this.addressesService.setEditAddressOfCompanyForm(this.data.element);
    }
  }

  onAutocompleteChange(event: any) {
    if (this.data && this.data.onAutocompleteChange) {
      this.data.onAutocompleteChange(event);
    }
  }

  onAutocompleteSelected(event: any) {
    if (this.data && this.data.onAutocompleteSelected) {
      this.data.onAutocompleteSelected(event);
    }
    this.setCheckboxes();
  }

  setCheckboxes() {
    const selectedUuid = this.addressesService.addressToCompanyForm.controls['selectedAddress'].value?.uuid;
    const toggleControlState = (controlName: string, type: string) => {
      const address = this.addressesService.addressesOfCompany.find((a: any) => a.uuid === selectedUuid && a[type] === true);
      const control = this.addressesService.addressToCompanyForm.get(controlName);
      if (address) {
        control?.setValue(true);
        control?.disable();
      } else {
        control?.setValue(false);
        control?.enable();
      }
    };
    toggleControlState('invoice', 'invoice');
    toggleControlState('delivery', 'delivery');
  }

  displayPersonName(person: Person) {
    return person ? `${person.first_name} ${person.last_name}` : '';
  }

  displayAddressName(address: Address): string {
    return address
      ? `${address.street} ${address.house_number}${address.additional_information ? '/' + address.additional_information : ''} ${address.zip} ${address.city} ${address.country}`
      : '';
  }
}
