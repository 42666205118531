<div class="flex w-full flex-col items-center text-gray-700 lg:m-5">
  <!-- SELECT YEAR + MONTH -->
  <div class="flex w-full justify-between lg:w-4/5">
    <mat-form-field class="mr-1 w-1/2">
      <mat-label>Jahr</mat-label>
      <mat-select class="" [(ngModel)]="selectedYear" (ngModelChange)="getDays()" name="selectedYear">
        <mat-option *ngFor="let y of years" [value]="y">
          {{ y }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="ml-1 w-1/2">
      <mat-label>Monat</mat-label>
      <mat-select class="" [(ngModel)]="selectedMonthGerman" (ngModelChange)="getDays()" name="selectedMonth">
        <mat-option *ngFor="let m of sortedMonthsGerman" [value]="m">
          {{ m }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="w-full lg:w-4/5">
    <!-- TEMPLATE -->
    <div
      class="mb-10 overflow-auto rounded border-b-1 border-t-1 border-gray-300 sm:border-1 sm:p-5"
      *ngIf="templates.length > 0"
    >
      <mat-form-field class="mb-5 !w-1/4">
        <mat-label>Vorlage</mat-label>
        <mat-select [(ngModel)]="selectedTemplate">
          <mat-option *ngFor="let t of templates; let i = index" [value]="i">
            <span *ngIf="i === 0">neue Vorlage</span>
            <span *ngIf="i > 0">{{ t.name }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <table class="w-full">
        <tr>
          <td>Tag</td>
          <td>Start</td>
          <td>Ende</td>
          <td>Pause</td>
        </tr>
        <tr *ngFor="let d of ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag']; let i = index">
          <td class="!w-1/4 border px-2">{{ d }}</td>
          <td class="!w-1/4 border">
            <input class="w-full border-0 p-1" [(ngModel)]="templates[selectedTemplate].days[i].start" type="time" />
          </td>
          <td class="!w-1/4 border">
            <input class="w-full border-0 p-1" [(ngModel)]="templates[selectedTemplate].days[i].end" type="time" />
          </td>
          <td class="!w-1/4 border">
            <input class="w-full border-0 p-1" [(ngModel)]="templates[selectedTemplate].days[i].break" type="time" />
          </td>
        </tr>
        <tr>
          <td>
            <button
              class="mt-2 w-full rounded bg-gray-700 px-2 py-1 text-gray-100"
              *ngIf="selectedTemplate === 0"
              (click)="createTemplate()"
            >
              Vorlage erstellen
            </button>
            <button
              class="mt-2 w-full rounded bg-gray-700 px-2 py-1 text-gray-100"
              *ngIf="selectedTemplate > 0"
              (click)="useTemplate()"
            >
              Vorlage anwenden
            </button>
          </td>
          <td></td>
          <td></td>
          <td>
            <button
              class="mt-2 w-full rounded bg-gray-700 px-2 py-1 text-gray-100"
              *ngIf="selectedTemplate > 0"
              (click)="deleteTemplate()"
            >
              Vorlage löschen
            </button>
          </td>
        </tr>
      </table>
    </div>

    <!-- TIMETABLE -->
    <financehub-time-tracking-input-template></financehub-time-tracking-input-template>

    <div class="mb-10 overflow-auto">
      <table class="mat-elevation-z8" [dataSource]="dataSource" mat-table>
        <ng-container matColumnDef="day">
          <th *matHeaderCellDef mat-header-cell>Tag</th>
          <td class="whitespace-nowrap" *matCellDef="let element" mat-cell>
            <span class="inline-block w-7">{{ element.dayName.slice(0, 2) }},</span>
            <span
              >{{ (element.day < 10 ? '0' : '') + element.day }}.{{
                (selectedMonthIndex + 1 < 10 ? '0' : '') + (selectedMonthIndex + 1)
              }}.{{ selectedYear }}</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="start">
          <th *matHeaderCellDef mat-header-cell>Start</th>
          <td *matCellDef="let element" mat-cell>
            <input
              class="w-full border-0 p-1"
              *ngIf="element.dayName !== 'Samstag' && element.dayName !== 'Sonntag'"
              [(ngModel)]="element.start"
              [ngClass]="{
                'bg-red-100':
                  element.absence === 'Urlaub' ||
                  element.absence === 'Sonderurlaub' ||
                  element.absence === 'Krankenstand' ||
                  element.absence === 'Feiertag'
              }"
              [readonly]="element.absence"
              (ngModelChange)="calcWorkinghours(element)"
              type="time"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="end">
          <th *matHeaderCellDef mat-header-cell>Ende</th>
          <td *matCellDef="let element" mat-cell>
            <input
              class="w-full border-0 p-1"
              *ngIf="element.dayName !== 'Samstag' && element.dayName !== 'Sonntag'"
              [(ngModel)]="element.end"
              [ngClass]="{
                'bg-red-100':
                  element.absence === 'Urlaub' ||
                  element.absence === 'Sonderurlaub' ||
                  element.absence === 'Krankenstand' ||
                  element.absence === 'Feiertag'
              }"
              [readonly]="element.absence"
              (ngModelChange)="calcWorkinghours(element)"
              type="time"
            />
          </td>
        </ng-container>

        <ng-container matColumnDef="break">
          <th *matHeaderCellDef mat-header-cell>Pause</th>
          <td *matCellDef="let element" mat-cell>
            <input
              class="w-full border-0 p-1"
              *ngIf="element.dayName !== 'Samstag' && element.dayName !== 'Sonntag'"
              [(ngModel)]="element.break"
              [ngClass]="{
                'bg-red-100':
                  element.absence === 'Urlaub' ||
                  element.absence === 'Sonderurlaub' ||
                  element.absence === 'Krankenstand' ||
                  element.absence === 'Feiertag'
              }"
              [readonly]="element.absence"
              (ngModelChange)="calcWorkinghours(element)"
              type="time"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="workingTime">
          <th *matHeaderCellDef mat-header-cell>Arbeitszeit</th>
          <td *matCellDef="let element" mat-cell>
            <input
              class="w-full border-0 p-1"
              *ngIf="element.dayName !== 'Samstag' && element.dayName !== 'Sonntag'"
              [(ngModel)]="element.working"
              [ngClass]="{
                'bg-red-100':
                  element.absence === 'Urlaub' ||
                  element.absence === 'Sonderurlaub' ||
                  element.absence === 'Krankenstand' ||
                  element.absence === 'Feiertag'
              }"
              readonly
              type="text"
            />
          </td>
        </ng-container>
        <ng-container matColumnDef="absence">
          <th *matHeaderCellDef mat-header-cell>Abwesenheit</th>
          <td *matCellDef="let element" mat-cell>
            <mat-select
              class="w-full border-0 p-1"
              *ngIf="element.dayName !== 'Samstag' && element.dayName !== 'Sonntag'"
              [(ngModel)]="element.absence"
              [ngClass]="{
                'bg-red-100':
                  element.absence === 'Urlaub' ||
                  element.absence === 'Sonderurlaub' ||
                  element.absence === 'Krankenstand' ||
                  element.absence === 'Feiertag'
              }"
              (ngModelChange)="checkAbsence(element)"
              type="text"
            >
              <mat-option *ngFor="let a of absencesToSelect" [value]="a">{{ a }}</mat-option>
            </mat-select>
          </td>
        </ng-container>
        <ng-container matColumnDef="note">
          <th *matHeaderCellDef mat-header-cell>Notiz</th>
          <td *matCellDef="let element" mat-cell>
            <input
              class="w-full border-0 p-1"
              *ngIf="element.dayName !== 'Samstag' && element.dayName !== 'Sonntag'"
              [(ngModel)]="element.note"
              [ngClass]="{
                'bg-red-100':
                  element.absence === 'Urlaub' ||
                  element.absence === 'Sonderurlaub' ||
                  element.absence === 'Krankenstand' ||
                  element.absence === 'Feiertag'
              }"
              type="text"
            />
          </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr
          *matRowDef="let row; columns: displayedColumns"
          [ngClass]="{
            '!bg-red-100':
              row.absence === 'Urlaub' ||
              row.absence === 'Sonderurlaub' ||
              row.absence === 'Krankenstand' ||
              row.absence === 'Feiertag'
          }"
          mat-row
        ></tr>
      </table>
    </div>
    <div *ngIf="existingTimeTracking" class="my-2 font-medium text-sm text-red-400">Time tracking exists for selected month.</div>
    <button
      class="mt-2 w-auto rounded bg-gray-700 px-2 py-1 text-gray-100"
      (click)="saveUserTimeTracking()"
    >
      {{ existingTimeTracking ? 'Update Time Tracking' : 'Save Time Tracking' }}
    </button>
  </div>
</div>
