<div class="!bg-background py-10 px-[2vw] md:px-[10vw] flex flex-col items-center">
  <div class="text-primary font-light w-full mb-5 flex justify-between items-center">
    <span class="text-start text-xl md:text-3xl">All Invoices</span>
    <mat-form-field class="mat-form-field-normal mat-form-field-filter font-medium !text-sm w-2/5 lg:w-2/5 !mb-0" appearance="outline">
      <mat-label>{{config.table.filter}}</mat-label>
      <input
        [(ngModel)]="filterTerm"
        (keydown)="refetchInvoices($event)"
        matInput
      />
      <mat-icon
        class="cursor-pointer"
        *ngIf="filterTerm"
        (click)="clearFilterAndRefetch()"
        matSuffix
        >close</mat-icon
      >
    </mat-form-field>
  </div>
  <div class="overflow-x-auto w-full">
    <table [dataSource]="dataSource" mat-table matSort [matSortActive]="sortState.active" [matSortDirection]="sortState.direction" (matSortChange)="resetPaginationAndRefetch()">
      <ng-container matColumnDef="invoice_number">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by number">Invoice Nr.</th>
        <td *matCellDef="let element" mat-cell>{{ element.invoice_number }}</td>
      </ng-container>
      <ng-container matColumnDef="invoice_date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by invoiceDate">Invoice Date</th>
        <td *matCellDef="let element" mat-cell>{{ element.invoice_date | date }}</td>
      </ng-container>
      <ng-container matColumnDef="due_date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by dueDate">Due Date</th>
        <td *matCellDef="let element" mat-cell>{{ element.due_date | date }}</td>
      </ng-container>
      <ng-container matColumnDef="customer_id">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by customerID">Client</th>
        <td *matCellDef="let element" mat-cell>
          <div
            class="line-clamp-2"
            [matTooltip]="
              element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')'
            "
          >
            {{ element.customer ? element.customer.name_company : 'Gelöschter Kunde (ID: ' + element.customer_id + ')' }}
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="subtotal">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by net">Net</th>
        <td class="!text-end" *matCellDef="let element" mat-cell>
          {{ element.subtotal | number: '1.2-2' : 'de-DE' }}&nbsp;€
        </td>
      </ng-container>
      <ng-container matColumnDef="gross">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by gross">Gross</th>
        <td class="!text-end" *matCellDef="let element" mat-cell>
          {{ element.subtotal + element.total_taxes | number: '1.2-2' : 'de-DE' }}&nbsp;€
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th *matHeaderCellDef mat-header-cell mat-sort-header sortActionDescription="Sort by status">Status</th>
        <td *matCellDef="let element; let even = even" mat-cell (click)="$event.stopPropagation()">
          <mat-form-field class="invoice-list-status-select hide-field !mb-0" appearance="outline">
            <select matNativeControl required (ngModelChange)="statusChanged($event, element._id)" [(ngModel)]="element.status">
              <option [value]="s.type" *ngFor="let s of availableStatuses(element.status)">{{s.title}}</option>
            </select>
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="icon">
        <th *matHeaderCellDef mat-header-cell></th>
        <td *matCellDef="let element" mat-cell>
          <div class="flex flex-col justify-end xs:flex-row">
            <mat-icon class="mr-3 cursor-pointer" (click)="$event.stopPropagation(); downloadPdf(element)">picture_as_pdf</mat-icon>
            <mat-icon class="mr-3 cursor-pointer" [routerLink]="'../invoice/' + element._id">edit</mat-icon>
            <mat-icon class="cursor-pointer" (click)="$event.stopPropagation(); openDeleteDialog(element)">delete</mat-icon>
          </div>
        </td>
      </ng-container>
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let element; let even = even; columns: displayedColumns" class="!bg-background cursor-pointer hover:!bg-white" mat-row [routerLink]="'../invoice/' + element._id" 
      ></tr>
    </table>
    <mat-paginator (page)="refetchInvoices()" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>