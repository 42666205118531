import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { Person } from '../../interfaces/person';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { defaultConfig, FilterConfig } from 'src/app/interfaces/filter-config';
import { AddService } from '../add.service';
import { ProjectSettings } from 'src/assets/config/project-config';

export const GET_PERSONS = gql`
  query persons($filter: FilterInput) {
    persons (filter: $filter) {
      data {
        first_name
        last_name
        uuid
        bds_number
        birthday
        phone_private
        mail_private
        fax
        gender
        title_academic
        title_academic_after
      }
      totalCount
    }
  }
`;

const GET_PERSON = gql`
  query person($uuid: String!) {
    person(uuid: $uuid) {
      first_name
      last_name
      uuid
      bds_number
      birthday
      phone_private
      mail_private
      fax
      gender
      title_academic
      title_academic_after
    }
  }
`;

const GET_FIRST_BDS_NUMBER =  gql`
  query personFirstBdsNumber {
    personFirstBdsNumber
  }
`;

const CREATE_PERSON = gql`
  mutation CreatePerson($createPersonInput: CreatePersonInput) {
    createPerson(createPersonInput: $createPersonInput) {
      _id
    }
  }
`;

const UPDATE_PERSON = gql`
  mutation UpdatePerson($uuid: String!, $updatePersonInput: UpdatePersonInput) {
    updatePerson(uuid: $uuid, updatePersonInput: $updatePersonInput) {
      _id
    }
  }
`;

const DELETE_PERSON = gql`
  mutation DeletePerson($uuid: String!) {
    deletePerson(uuid: $uuid) {
      _id
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class PersonsService {
  constructor(private apollo: Apollo, private addService: AddService) {}

  personToCompanyForm!: FormGroup;
  editPersonOfCompanyForm!: FormGroup;
  availablePersons: Person[] = [];
  config = ProjectSettings;
  personSelected = false;
  isEditPerson: any;
  editPerson = false;

  setPersonToCompanyForm() {
    this.personToCompanyForm = new FormGroup({
      selectedPerson: new FormControl('', Validators.required),
    });
  }

  setEditPersonOfCompanyForm(person: any) {
    this.editPersonOfCompanyForm = this.addService.createFormGroup(this.config.fields.personCompanyInfo);
    Object.keys(this.editPersonOfCompanyForm.controls).forEach(controlName => {
      const controlValue = person[controlName];
      const type = this.config.fields.company.find((f: any) => f.controlName === controlName)?.type;
      this.editPersonOfCompanyForm.controls[controlName].setValue(type === 'number' ? parseInt(controlValue) : controlValue);
    });
  }

  getPersons(filterConfig: FilterConfig): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_PERSONS,
      variables: {
        filter: {
          limit_count: filterConfig.limit_count,
          limit_start: filterConfig.limit_start,
          sort_field: filterConfig.sort_field,
          sort_order: filterConfig.sort_order,
          filter_term: filterConfig.filter_term,
        }
      },
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  getPerson(uuid: string): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_PERSON,
      variables: { uuid },
    }).valueChanges;
  }

  getFirstBdsNumber(): Observable<any> {
    return this.apollo.watchQuery<any>({
      query: GET_FIRST_BDS_NUMBER,
      fetchPolicy: 'network-only',
    }).valueChanges;
  }

  savePerson(person: Person): Observable<any> {
    const personInput = {
      first_name: person.first_name,
      last_name: person.last_name,
      bds_number: person.bds_number,
      uuid: person.uuid,
      birthday: person.birthday,
      phone_private: person.phone_private,
      mail_private: person.mail_private,
      fax: person.fax,
      gender: person.gender,
      title_academic: person.title_academic,
      title_academic_after: person.title_academic_after,
    };
    return this.apollo.mutate({
      mutation: CREATE_PERSON,
      variables: { createPersonInput: personInput },
      refetchQueries: [{ query: GET_PERSONS, variables: { filter: defaultConfig }  }],
    });
  }

  updatePerson(uuid: string, person: Person): Observable<any> {
    const personInput = {
      first_name: person.first_name,
      last_name: person.last_name,
      bds_number: person.bds_number,
      birthday: person.birthday,
      phone_private: person.phone_private,
      mail_private: person.mail_private,
      fax: person.fax,
      gender: person.gender,
      title_academic: person.title_academic,
      title_academic_after: person.title_academic_after,
    };
    return this.apollo.mutate({
      mutation: UPDATE_PERSON,
      variables: { uuid, updatePersonInput: personInput },
      refetchQueries: [{ query: GET_PERSONS, variables: { filter: defaultConfig } }, { query: GET_PERSON, variables: { uuid } }],
    });
  }

  deletePerson(uuid: string): Observable<any> {
    return this.apollo.mutate({ mutation: DELETE_PERSON, variables: { uuid } });
  }
}
