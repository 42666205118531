import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoiceService } from '../../../services/finance/invoice.service';
import { Subscription } from 'rxjs';
import { PersonsService } from '../../../services/crm/persons.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarTemplateComponent } from '../../../components/snackbar-template/snackbar-template.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectSettings } from '../../../../assets/config/project-config';
import { Dialog } from '@angular/cdk/dialog';
import { DetailTemplateComponent } from 'src/app/components/detail-template/detail-template.component';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'financehub-person-detail',
  templateUrl: './person-detail.component.html',
  standalone: true,
  imports: [DetailTemplateComponent, MatIconModule]
})
export class PersonDetailComponent implements OnInit, OnDestroy {
  constructor(
    public invoiceService: InvoiceService,
    private personsService: PersonsService,
    public financialService: FinancialService,
    public route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public dialog: Dialog,
  ) {
  }

  private querySubscription!: Subscription;
  config!: any;
  uuid!: string;
  personForm!: FormGroup;
  person!: any;
  editPersonActive = false;

  ngOnInit() {
    this.config = ProjectSettings;
    this.createOrUpdatePersonForm();
    this.route.paramMap.subscribe((params) => {
      this.uuid = this.financialService.entityEditUuid;
      this.querySubscription = this.personsService.getPerson(this.uuid).subscribe(({ data, loading }) => {
        this.person = { ...data.person };
        this.createOrUpdatePersonForm(this.person);
      });
    });
  }

  createOrUpdatePersonForm(person: any = {}) {
    const formGroupConfig: { [key: string]: FormControl } = {};
    this.config.fields.person.forEach((field: any) => {
      const validators = field.validation ? [Validators.required] : [];
      const value = person[field.controlName] ?? null;
      formGroupConfig[field.controlName] = new FormControl(
        { value: value, disabled: true },
        validators
      );
    });
    this.personForm = new FormGroup(formGroupConfig);
  }

  editPerson() {
    this.editPersonActive = true;
    this.enableFields();
  }

  enableFields() {
    Object.keys(this.personForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.personForm.get(controlName)?.enable() : null;
    })
  }

  updatePerson() {
    Object.keys(this.personForm.controls).forEach(controlName => {
      const controlValue = this.personForm.get(controlName)?.value;
      const type = this.config.fields.person.find((f: any) => f.controlName === controlName).type;
      this.person[controlName] = type === 'number' ? parseInt(controlValue) : controlValue;
    })
    this.personsService.updatePerson(this.uuid, this.person).subscribe(({ data, loading }) => {
      this.editPersonActive = false;
      this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.updatedPerson} });
    });
    this.editPersonActive = false;
    this.disableFields();
  }

  disableFields() {
    Object.keys(this.personForm.controls).forEach(controlName => {
      controlName !== 'uuid' ? this.personForm.get(controlName)?.disable() : null;
    })
  }

  ngOnDestroy() {
    this.querySubscription.unsubscribe();
  }

}