<div class="!bg-background py-10 px-[2vw] md:px-[10vw] flex gap-x-10 relative">
  <div class="flex flex-col w-full">
      <financehub-list-template 
        entity="user"
        heading="Users"
        [addButton]="config.button.addUser"
        [array]="config.tableColumns.users"
        [dataSource]="dataSource"
        [sortActive]="sortState.active"
        [sortDirection]="sortState.direction"
        [loading]="loading"
        [isEditable]="false"
        (clickedDelete)="openDeleteDialog($event, 'users')"
        (filterChanged)="applyFilter($event, dataSource)"
        (filterDelete)="deleteValue(dataSource)"
      />
  </div>
</div>