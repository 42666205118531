<div class="!bg-background py-10 px-[2vw] md:px-[10vw] flex gap-x-10 relative">
    <div class="flex flex-col w-full">
        <financehub-company-list class="w-full min-h-[300px]"/>
        <financehub-person-list class="w-full min-h-[300px]"/>
        <financehub-address-list class="w-full min-h-[300px]"/>
    </div>
    <div class="fixed bg-overlay top-0 right-0 bottom-0 left-0 z-50" (click)="financialService.resetEntity()" *ngIf="financialService.entityEdit">
        <div 
          class="fixed right-0 md:shadow-xl !bg-background top-0 bottom-0 border-1 border-gray-200 md:rounded-l-xl p-5 z-50 left-0"
          [ngClass]="{'md:left-[40%]': financialService.entityEdit === 'company', 'md:left-[60%]': financialService.entityEdit === 'address' || financialService.entityEdit === 'person'}"
          (click)="$event.stopPropagation()">
          <financehub-company-detail *ngIf="financialService.entityEdit === 'company'"/>
          <financehub-address-detail *ngIf="financialService.entityEdit === 'address'"/>
          <financehub-person-detail *ngIf="financialService.entityEdit === 'person'"/>
        </div>
      </div>
</div>