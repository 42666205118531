<div class="flex w-full justify-center">
    <form
        class="mt-5 flex grid w-full flex-col items-start mx-4 gap-x-2 grid-cols-12 lg:mx-0 lg:gap-x-5"
        [formGroup]="formGroup"
        (submit)="updateEntity($event); $event.stopPropagation()"
    >
        <financehub-input-template 
            *ngFor="let f of array"
            [entity]="entity"
            [class]="f.class" 
            [formGroup]="formGroup"
            [controlName]="f.controlName" 
            [label]="f.name" 
            [type]="f.type"
            [optionsArray]="f.optionsArray ? f.optionsArray : optionsArray"
            [readonly]="f.readonly"
            [placeholder]="f.placeholder"
        />
        <mat-toolbar class="row-start-12 col-start-1 col-span-12 !bg-white !bg-opacity-0 lg:!px-0">
            <button class="w-full" *ngIf="!editActive" (click)="editEntity($event); $event.stopPropagation()" mat-raised-button color="primary">
                <mat-icon> edit </mat-icon> {{editButton}}
            </button>
            <button class="w-full" *ngIf="editActive" [disabled]="formGroup.invalid" mat-raised-button color="primary" type="submit">
                <mat-icon> save </mat-icon> {{saveButton}}
            </button>
        </mat-toolbar>
    </form>
</div>