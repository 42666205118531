import { Component, OnInit } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProjectSettings } from 'src/assets/config/project-config';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarTemplateComponent } from 'src/app/components/snackbar-template/snackbar-template.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgForOf } from '@angular/common';
import { KeycloakAdminService } from 'src/app/services/keycloak-admin.service';
import { KeycloakProfile } from 'keycloak-js';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { InputTemplateComponent } from 'src/app/components/input-template/input-template.component';
import { FinancialService } from 'src/app/services/finance/financial.service';
import { UserTimeTrackingService } from 'src/app/services/crm/user-time-tracking.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'financehub-time-tracking-export',
  templateUrl: './time-tracking-export.component.html',
  standalone: true,
  imports: [
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    NgForOf,
    MatAutocompleteModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    InputTemplateComponent,
  ],
})
export class TimeTrackingExportComponent implements OnInit {
    constructor(
        private keycloakAdminService: KeycloakAdminService,
        private userTimeTrackingService: UserTimeTrackingService,
        private _snackBar: MatSnackBar,
    ) {}

    config = ProjectSettings;

    months: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    years: number[] = [];
    selectedYear: number = new Date().getFullYear();
    selectedMonth: number = new Date().getMonth() + 1;
    selectedUser: KeycloakProfile | null = null;
    users: KeycloakProfile[] = [];

    async ngOnInit() {
        this.getYears();
        this.keycloakAdminService.getUsers().subscribe(({ data, loading }) => {
            this.users = data.users.data;
        });
    }

    getYears() {
        const currentDate = new Date();
        for (let i = 0; i < 2; i++) {
          this.years.push(currentDate.getFullYear() + i - 1);
        }
      }

    downloadPdf() {
        if (!this.selectedUser) {
            this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message: this.config.snackbar.incompleteForm } })
            return;
        }
        this.userTimeTrackingService.getTimeTrackingPdf({ 
            user_uuid: this.selectedUser.id, 
            first_name: this.selectedUser.firstName, 
            last_name: this.selectedUser.lastName, 
            year: this.selectedYear, 
            month: this.selectedMonth, 
        }).subscribe({
            next: (response: Blob) => {
                // Create a URL for the PDF
                const url = window.URL.createObjectURL(response);
            
                // Create a link element and click it to download the file
                const link = document.createElement('a');
                link.href = url;
                link.download = `time_tracking_${this.selectedUser?.lastName}_${this.selectedUser?.firstName}_${this.selectedYear}_${this.selectedMonth}.pdf`;
                link.click();
            
                // Clean up the URL object after download
                window.URL.revokeObjectURL(url);
            },
            error: (error) => {
                const message = error.status === 404 ? 'No time tracking data found for the selected user/month' : 'An error occured';
                this._snackBar.openFromComponent(SnackbarTemplateComponent, { data: { button: this.config.snackbar.confirm, message } })
            }
        });
    }

    getUserName(user: KeycloakProfile) {
        return user.firstName + ' ' + user.lastName;
    }

    onUserSelected(value: KeycloakProfile) {
        this.selectedUser = value;
    }

}
