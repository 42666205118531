import { Component } from '@angular/core';
import { CsvImportComponent } from '../csv-import/csv-import.component';
import { CommonModule } from '@angular/common';
import { TimeTrackingExportComponent } from '../time-tracking-export/time-tracking-export.component';


@Component({
  selector: 'financehub-admin',
  templateUrl: './admin.component.html',
  standalone: true,
  imports: [
    CsvImportComponent,
    TimeTrackingExportComponent,
  ],
})
export class AdminComponent {

}
